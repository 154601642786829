import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';//ketu jane instaluar me kete komande npm install react-slick slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import p1 from '../Assets/images/p1.png';
import p2 from '../Assets/images/p2.png';
import p3 from '../Assets/images/p3.png';
import p4 from '../Assets/images/p4.png';

const Project = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <section className="project py-5" id="project">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 text-center mx-auto col-12">
                        <div className="col-lg-8 mx-auto">
                            <h2>Things I have designed for digital media agencies</h2>
                        </div>
                        <Slider {...settings}>
                            <div className="item">
                                <div className="project-info">
                                    <img src={p1} className="img-fluid" alt="project image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="project-info">
                                    <img src={p2} className="img-fluid" alt="project image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="project-info">
                                    <img src={p3} className="img-fluid" alt="project image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="project-info">
                                    <img src={p4} className="img-fluid" alt="project image" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Project;
