import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com'; // Updated import statement

const Contact = () => {
  const form = useRef();
  const [isAlertVisible, setAlertVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6gqevtl', 'template_syfey2s', form.current, 'QwKzXeNv1W03Na2F0')
      .then((result) => {
          console.log(result.text);
          setAlertVisible(true); // Show the alert message
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section className="contact py-5" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mr-lg-5 col-12">
            {/* Google Map iframe */}
            <div className="google-map w-100">
              <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11983.87328724194!2d19.8200087!3d41.3313022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa049d820de5de59c!2selectronic%20service%20albania!5e0!3m2!1sen!2s!4v1630866917496!5m2!1sen!2s" width="100%" height="400px" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
            </div>

            {/* Contact info */}
            <div className="contact-info d-flex justify-content-between align-items-center py-4 px-lg-5">
              <div className="contact-info-item">
                <h3 className="mb-3 text-white">Let's stay connected on social media</h3>
                <p className="footer-text mb-0">+355685848484</p>
                <p><a href="mailto:hello@company.co">b.hoxha@webs-esolutions.com</a></p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="contact-form">
              <h2 className="mb-4">Interested in working together? Let's talk</h2>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-12">
                    <label>Name</label>
                    <input type="text" name="name" className="form-control" required />
                  </div>
                  <div className="col-12">
                    <label>Email</label>
                    <input type="email" name="email" className="form-control" required />
                  </div>
                  <div className="col-12">
                    <label>Message</label>
                    <textarea name="message" className="form-control" rows="6" required></textarea>
                  </div>
                  <div className="col-12">
                    <input type="submit" className="form-control submit-btn" value="Send Message" />
                  </div>
                </div>
              </form>
              {/* Success alert message */}
              {isAlertVisible && (
                <div className="alert alert-success mt-3" role="alert">
                  Thank you! Your message has been sent successfully.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
