import React from 'react';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/unicons.css';

const Footer = () => {
    return (
      
        <footer class="footer py-5">
             <div class="container">
                  <div class="row">
   
                       <div class="col-lg-12 col-12">                                
                           <p class="copyright-text text-center">Copyright &copy; 2019 Webs-Esolutions. All rights reserved</p>
                           <p class="copyright-text text-center">Powered by <a rel="nofollow" href="#">Bilbil Hoxha</a></p>
                       </div>
                       
                  </div>
             </div>
        </footer>
    );
}

export default Footer;
