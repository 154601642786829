import React, { useState } from 'react';
import './index.css'; // Import your app-wide CSS file
import Menu from './Menu/Menu';
import Footer from './Footer/Footer';
import Contact from './Contact/Contact';
import About from './About/About';
import Projects from './Projects/Projects';
import Resume from './Resume/Resume';



const App = () => {
 return (
    <div>
      {/* Conditionally render Header or Login based on login status */}

      <Menu/>
      <About/>
      <Projects/>
      <Resume/>
      <Contact/>
      <Footer />
    </div>
  );
};

export default App;