import React, { useState } from 'react';
import './Menu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/unicons.css';

const Menu = () => {
    // State to track the mobile menu visibility
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    // State to track the dark mode
    const [darkMode, setDarkMode] = useState(false);

    // Function to toggle the mobile menu visibility
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    // Function to toggle dark mode
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        // Toggle dark mode class on body
        document.body.classList.toggle('dark-mode');
    };

    // Function to close the mobile menu when a menu item is clicked
    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <nav className={`navbar navbar-expand-sm navbar-light ${mobileMenuOpen ? 'mobile-menu-open' : ''} ${darkMode ? 'dark-mode' : ''}`}>
            <div className="container">
                <a className="navbar-brand" href="index.html"><i className='uil uil-user'></i>Webs Solutions</a>

                <button className="navbar-toggler" type="button" onClick={toggleMobileMenu}>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${mobileMenuOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <a href="#about" className="nav-link" onClick={closeMobileMenu}><span data-hover="About">About</span></a>
                        </li>
                        <li className="nav-item">
                            <a href="#project" className="nav-link" onClick={closeMobileMenu}><span data-hover="Projects">Projects</span></a>
                        </li>
                        <li className="nav-item">
                            <a href="#resume" className="nav-link" onClick={closeMobileMenu}><span data-hover="Resume">Resume</span></a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link" onClick={closeMobileMenu}><span data-hover="Contact">Contact</span></a>
                        </li>
                        <li className="nav-item">
                            {/* Button to toggle dark mode */}
                            <button className="nav-link" onClick={toggleDarkMode}>
                                {darkMode ? 'Light Mode' : 'Dark Mode'}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Menu;
