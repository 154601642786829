import React from 'react';
import ReactDOM from 'react-dom';
import App from './app'; // Adjust the path if necessary
import './index.css'; // Import CSS file
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
