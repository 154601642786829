import React from 'react';
import './Resume.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/unicons.css';

const Resume = () => {
    return (
        <section className="resume py-5 d-lg-flex justify-content-center align-items-center" id="resume">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <h2 className="mb-4">Experiences</h2>
                        <div className="timeline">
                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2024</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Software Engineer</span><small>Teleperformance</small></h3>
                                    <p>In my experience as a software engineer, I've had the opportunity to contribute to the development of innovative software solutions across various domains. Collaborating within agile teams, I've been involved in the full software development lifecycle, from requirements gathering and design to implementation and testing. Utilizing programming languages such as Java, Python, and JavaScript, I've crafted scalable and efficient codebases, delivering high-quality software products that meet customer needs and exceed expectations.</p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Security Engineer Analyst</span><small>Teleperformance</small></h3>
                                    <p>In my role as a Security Engineering Analyst at Mimecast and Zscaler, I've had the privilege of working at the forefront of cybersecurity, protecting organizations from evolving threats. My responsibilities have involved analyzing security events and incidents, identifying potential risks, and implementing effective countermeasures to safeguard critical assets. Through collaboration with cross-functional teams and continuous research into emerging threats, I've contributed to enhancing the resilience of network infrastructures and ensuring comprehensive threat mitigation strategies.</p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2020</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Network Administrator</span><small>Pc Store</small></h3>
                                    <p>In my role as a network administrator, I've been responsible for managing and maintaining the integrity of organizational networks. This includes configuring routers, switches, firewalls, and other network devices to ensure optimal performance and security. I've also implemented monitoring tools and protocols to proactively identify and resolve network issues, minimizing downtime and ensuring seamless connectivity for users across the network.</p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2017</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Web Developer</span><small>AlbASP</small></h3>
                                    <p>As a web developer, I've had the opportunity to collaborate on diverse projects, ranging from simple static websites to complex web applications. I've honed my skills in frontend development, mastering technologies like HTML, CSS, and JavaScript to create intuitive user interfaces and engaging user experiences. Additionally, I've gained experience in backend development, using frameworks like Node.js and databases like MongoDB to build scalable and efficient server-side functionalities, ensuring robust and seamless web solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <h2 className="mb-4 mobile-mt-2">Certifications</h2>
                        <div className="timeline">
                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2023</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Java from Scratch</span><small></small></h3>
                                    <p>"Java From Scratch" is an introductory course designed to teach programming with Java, starting from the fundamentals. Participants learn the core concepts of Java programming language, including syntax, data types, control flow, and object-oriented programming principles. Through hands-on exercises and projects, students gain practical experience in writing Java code and building simple applications, setting a solid foundation for further Java development endeavors.</p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Network Engineer(CCNA)</span></h3>
                                    <p><a rel="nofollow" href="#">CCNA</a> The Cisco Certified Network Associate (CCNA) certification is a globally recognized accreditation validating foundational knowledge in networking. It equips individuals with essential skills to configure, troubleshoot, and operate networks effectively, spanning various Cisco devices and technologies. By earning CCNA certification, professionals demonstrate competence in areas like routing and switching, network security, and network access control.</p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2020</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Certified Network Security Specialist (CNSS)</span><small></small></h3>
                                    <p>The Certified Network Security Specialist (CNSS) certification is designed to validate expertise in securing network infrastructures against cyber threats. It encompasses a comprehensive understanding of network security principles, protocols, and best practices. CNSS-certified professionals are proficient in implementing robust security measures, conducting risk assessments, and safeguarding networks from unauthorized access and malicious activities.</p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2020</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Web Developer</span><small></small></h3>
                                    <p>A web developer is a professional responsible for designing, building, and maintaining websites and web applications. They utilize various programming languages such as HTML, CSS, and JavaScript, along with frameworks and libraries like React or Angular, to create responsive and interactive web experiences. Web developers work closely with clients or stakeholders to understand requirements, implement designs, and ensure websites are functional, visually appealing, and optimized for performance across different devices and browsers.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Resume;
