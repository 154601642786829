import React from 'react';
import './About.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/unicons.css';
import BHCV from '../cv/BHCV.pdf'; // Import the PDF file
import b1Svg from '../Assets/images/b1.svg'; // Import the SVG file

const About = () => {
    return (
        <section className="about full-screen d-lg-flex justify-content-center align-items-center" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                        <div className="about-text">
                            <small className="small-text">Welcome to <span className="mobile-block">my passion!</span></small>
                            <h1 className="animated animated-text">
                                <span className="mr-2">Hey, I'm</span>
                                <div className="animated-info">
                                    <span className="animated-item">Bilbil Hoxha</span>
                                    <span className="animated-item">Software Engineer</span>
                                    <span className="animated-item">UI Specialist</span>
                                </div>
                            </h1>

                            <p>As a seasoned software engineer, I adeptly utilize Java for backend development, employing frameworks like Spring to streamline coding and ensure scalability. MySQL serves as my go-to database solution, where I excel in schema design, optimization, and data security. On the frontend, I craft engaging user experiences using HTML, CSS, and JavaScript, leveraging modern libraries like React.js to enhance interactivity. With a holistic approach to development, I seamlessly bridge backend and frontend functionalities, ensuring cohesive and efficient web applications. My problem-solving skills and collaborative nature enable me to tackle complex challenges and deliver high-quality solutions in a team environment.</p>

                            <div className="custom-btn-group mt-4">
                                <a href={BHCV} className="btn mr-lg-2 custom-btn" download><i className='uil uil-file-alt'></i> Download Resume</a>
                                <a href="#contact" className="btn custom-btn custom-btn-bg custom-btn-link">Let's talk</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="about-image svg">
                            <img src={b1Svg} className="img-fluid" alt="svg image" /> {/* Use the imported SVG */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
